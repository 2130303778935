@import url(https://fonts.googleapis.com/css2?family=Lora:wght@400;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  color: black;
}

h1 {
  font-size: 70px;
  margin-bottom: 40;
}

h2 {
  font-size: 40px;
  margin-bottom: 40;
  margin-top: 0;
  font-weight: 400;
  color: white;
}

.App {
  background-color: white;
  text-align: center;
  min-height: 100vh;
  overflow: hidden;
  width: 100vw;
  position: relative;
  font-family: 'Lora', serif;
}

.App-bg {
  width: 130vw;
  pointer-events: none;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.content {
  position: relative;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.side-by-side {
  display: flex;
}

.side-by-side div p {
  text-align: left;
  font-size: 22px;
  line-height: 1.5

}

.body {
  padding: 40px 120px;
}

.cover-img {
  width: 100%;
}

.services {
  display: flex;
  width: 94%;
  margin: 0 auto;
  align-items: center;
  justify-content: space-evenly;

}

.services img {
  height: 50px;
}

.services img.spotify {
  height: 64px;
}

.services img.insta {
  height: 70px;
}

.button {
  text-decoration: none;
  opacity: .9;
  background-color: #479fff;
  color: white;
  text-transform: uppercase;
  font-weight: bold;
  border-radius: 20px;
  padding: 20px 40px;
  font-size: 22px;
  cursor: pointer;
  transition: opacity 120ms ease;
  display: inline-block;
}

.button:hover {
  opacity: 1;
}

a {
  color: #479fff;
  font-weight: bold;
  text-decoration: none;
}

.footer {
  background-color: #1a1a1a;
  padding: 60px 0;
  width: 100%;
  margin: -4px;
  color: white;
}

@media all and (max-width: 699px) {
  .side-by-side {
    flex-direction: column;
  }

  .body {
    padding: 40px;
  }

  h1 {
    font-size: 50px;
    margin-top: 20px;
  }
  .cover {
    max-width: 100%;
  }
  .App-bg {
    display: none;
  }
  .services {
    width: 100%;
    flex-direction: column;
    padding: 30px 0;
  }
  .services img {
    margin: 20px 0;
  }
  .App {
    padding-bottom: 0;
  }
}

.youtube {
  height: 50px;
}

.insta {
  height: 50px;
}
